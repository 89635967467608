<template>
  <b-sidebar
    id="add-new-brands-sidebar"
    :visible="isAddNewBrandSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-brand-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Brand
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="brandData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="Description"
            rules="required"
          >
            <b-form-group
              label="Description"
              label-for="Description"
            >
              <b-form-input
                id="description"
                v-model="brandData.description"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Description"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Url -->
          <validation-provider
            #default="validationContext"
            name="Url"
            rules="required"
          >
            <b-form-group
              label="Url"
              label-for="url"
            >
              <b-form-input
                id="url"
                v-model="brandData.url"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Url"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewBrandSidebarActive',
    event: 'update:is-add-new-brand-sidebar-active',
  },
  props: {
    isAddNewBrandSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
 
  setup(props, { emit }) {
    const toast = useToast()
    const blankBrand = {
      title: '',
      key: '',
      status: null,
    }

    const brandData = ref(JSON.parse(JSON.stringify(blankBrand)))
    const resetuserData = () => {
      brandData.value = JSON.parse(JSON.stringify(blankBrand))
    }

    const onSubmit = () => {
      const payload = {
        title: brandData.value.title,
        key: brandData.value.key,
        isActive: brandData.value.status.value,
      }
      store.dispatch('generalConfig/addGeneralConfig', payload)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-brand-sidebar-active', false)
        }).catch(e => {
          toast({
            component: ToastificationContent,
            props: {
              title: e.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      toast,
      brandData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-brands-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
  </style>
